import { _validators } from '@redsales/ds/utils'
import * as Yup from 'yup'

export const values = {
	password: '',
	context_password: '',
}

export const validation = Yup.object().shape({
	password: Yup.string()
		.required('Campo obrigatório')
		.test(
			'wordsLengthMoreThenOrEqual',
			' ',
			(value) => !value || _validators.wordsLengthMoreThenOrEqual(8)(value)
		)
		.test(
			'checkHasUppercaseChars',
			' ',
			(value) => !value || _validators.checkHasUppercaseChars(value)
		)
		.test(
			'checkHasLowercaseChars',
			' ',
			(value) => !value || _validators.checkHasLowercaseChars(value)
		)
		.test(
			'checkHasSpecialChars',
			' ',
			(value) => !value || _validators.checkHasSpecialChars(value)
		),

	repeated_password: Yup.string()
		.required('Campo obrigatório')
		.test(
			'validSamePassword',
			'Senhas não são iguais',
			(value, context) => context.parent.password === value
		),
})
