import './FormChangePassword.scss'
import { values, validation } from './form'
import useChangePassword from './hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import { Icon, Button } from '@redsales/ds'
import AuthFeedback from 'components/Auth/Feedback'
import Fields from 'components/Fields'
import If from 'components/If'
import { useForm as useFormHook } from 'react-hook-form'

const FormChangePassword = ({ location }) => {
	const {
		control,
		handleSubmit,
		trigger,
		getValues,
		formState: { isValid },
	} = useFormHook({
		defaultValues: values,
		mode: 'onChange',
		...(validation && { resolver: yupResolver(validation) }),
	})

	const {
		onSubmit,
		feedbacks,
		onConcluded,
		isRequestingAPI,
		onPasswordChange,
	} = useChangePassword({
		trigger,
		getValues,
	})

	const isForm = !feedbacks.error && !feedbacks.success

	return (
		<div className="change-password">
			<If condition={isForm}>
				<Icon name="key" className="change-password__top-icon" />

				<div className="change-password">
					<h1 className="change-password__title logged-out__title">
						Recadastrar senha
					</h1>

					<p className="change-password__description logged-out__description">
						Olá! Você precisa criar uma nova senha para acessar a sua conta.
					</p>

					<form
						className="change-password__form"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Fields.Input.Password
							type="password"
							name="password"
							control={control}
							checkValidations={true}
							placeholder="Nova senha"
							className="change-password__input"
							onChange={onPasswordChange}
						/>
						<Fields.Input.Password
							type="password"
							name="repeated_password"
							control={control}
							className="change-password__input"
							placeholder="Confirmar a nova senha"
						/>

						<Button
							size="big"
							type="submit"
							isLoading={isRequestingAPI}
							className="change-password__submit"
							disabled={!isValid || isRequestingAPI}
						>
							Criar senha
						</Button>
					</form>
				</div>
			</If>

			<If condition={feedbacks.success}>
				<AuthFeedback
					icon="party-popper"
					title="Senha cadastrada com sucesso!"
					description={`
							A sua senha foi cadastrada e você já pode <br />
							acessar a sua conta na plataforma.
						`}
					button="Voltar para a tela de acesso"
					onClick={onConcluded}
					data-test="feedback"
				/>
			</If>

			<If condition={feedbacks.error}>
				<AuthFeedback
					icon="lock"
					title="Erro na redefinição"
					description={`
						${feedbacks?.error?.message} <br>
						Entre em contato com o administrador da sua conta para solicitar o desbloqueio.
					`}
					button="Voltar para a tela de acesso"
					submitComponent="link"
					onClick={onConcluded}
					data-test="feedback"
				/>
			</If>
		</div>
	)
}

export default FormChangePassword
