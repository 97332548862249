import { navigate } from 'gatsby'
import { useParameters } from 'hooks/useParameters'
import jwtDecode from 'jwt-decode'
import { useState } from 'react'
import services from 'services'

const initialStates = {
	error: null,
	success: null,
}

const useChangePassword = ({ trigger, getValues }) => {
	const [feedbacks, setFeedbacks] = useState(initialStates)
	const [isRequestingAPI, setIsRequestingAPI] = useState(false)

	const { token } = useParameters('token')
	const email = jwtDecode(token)?.recover_email || ''

	const onSubmit = ({ password, repeated_password }) => {
		setIsRequestingAPI(true)

		services.auth
			.resetPassword({
				token,
				email,
				password,
				repeated_password,
			})
			.then(handleChangedPassword)
			.then(() => setIsRequestingAPI(false))
	}

	const onConcluded = () => {
		navigate(ROUTES.login.path)
	}

	const handleChangedPassword = ({ error, message }) => {
		setFeedbacks({
			error: error && { message },
			success: !error,
		})
	}

	const onPasswordChange = async () => {
		if (getValues('repeated_password')) {
			await trigger('repeated_password')
		}
	}

	return {
		onSubmit,
		feedbacks,
		onConcluded,
		isRequestingAPI,
		onPasswordChange,
	}
}

export default useChangePassword
