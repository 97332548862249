import { Button, Icon } from "@redsales/ds"

import If from "components/If"
import { INITIAL_LINK } from "./constants"

import "./AuthFeedback.scss"

const AuthFeedback = ({
	icon,
	title,
	description,
	button,
	onClick,
	submitComponent = "button",
	link = INITIAL_LINK,
	...props
}) => (
	<div className="auth-feedback" {...props}>
		<Icon name={icon} />

		<h1 className="auth-feedback__title">{title}</h1>
		<p
			className="auth-feedback__description"
			dangerouslySetInnerHTML={{ __html: description }}
		/>

		<If condition={submitComponent === "button"}>
			<Button size="big" onClick={onClick} className="auth-feedback__button">
				{button}
			</Button>
		</If>

		<If condition={submitComponent === "link"}>
			<Button
				variant="link"
				color="blue"
				onClick={onClick}
				className="auth-feedback__link"
			>
				{link?.icon || ""}
				{link?.text || ""}
			</Button>
		</If>
	</div>
)

export default AuthFeedback
